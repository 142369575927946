#navBar {
    background-color: #fff;
    border-bottom: transparent;
    box-shadow: 0 3px 15px rgba(0,0,0,0.2);
    position: fixed;
    width: 100%;
    min-height: 48px;
    display: inline-block;
    padding-left: 25px;
    padding-right: 25px;
    z-index: 999;
}
#main-navBar {
    display: flex;
    flex-direction: row-reverse;
    padding-right: 4%;
}

.navbar-btn-home {
    position: absolute;
    left: 75px;
    top: 5px;
}

.navbar-btn-home img {
    width: 50%;
    cursor: pointer;
}
#navbar-open-btn {
    position: absolute;
    right: 70px;
    top: 5px;
}

.navbar-btn svg{
    width: 2.5rem;
    height: 2.5rem;
}


#navbar-close-btn {
    position: absolute;
    right: 0;
    top: 5px;
}
#mobile-navBar {
    display: none;
}


@media only screen and (max-width:900px) {
    #mobile-menu {
        display: flex;
        flex-direction: column;
    }
    
    #mobile-menu .MuiTabs-flexContainer {
        display: flex;
        flex-direction: column !important;
        align-items: center !important;
    }

    #mobile-menu-header {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 150px;
    }

    #mobile-menu-drawer .MuiPaper-root {
        width: 80%;
    }
    
    #mobile-menu-drawer .navbar-btn-home-drawer {  
        position: relative;
        right: -270px;
        top: 8px;
    }
    
    #mobile-menu-drawer .navbar-btn-home-drawer img {
        width: 50%;
        cursor: pointer;
    }
    
    #mobile-menu-drawer .MuiTabs-indicator {
        display: none;
    }
    
    #mobile-menu-drawer span {
        font-size: 1.6rem !important;
        font-weight: 500;
        line-height: 50px;
    }
    .navbar-btn-home {
        left: 20px;
        top: 5px;
    }

    .navbar-btn-home img {
        width: 50%;
        cursor: pointer;
    }

    #mobile-navBar {
        display: initial;
    }

    #main-navBar {
        display: none;
    }

    #language-selector-container-mobile {
        position:absolute;
        top:5px;
        left:10px;
    }
}