.project-card {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.project-card:hover .project-card-img {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.project-card:hover .project-card-content {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 #552ef4fc;
}
.project-card-img {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    margin: 0 auto;
    min-width: 90%;
    max-height: 200px;
    min-height: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.project-card-content {
    display: flex;
    flex-direction: column;
    position:relative;
    top:-20px;
    width: 70%;
    padding: 0 25px;
    margin: 0 auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background: #fafafa;
    max-height: 300px;
    min-height: 300px;
}

.project-card-title {
    display: flex;
    flex-direction: column;
    justify-content:center;
    text-align: center;
    min-height: 80px;
    max-height: 80px;
}

.project-card-description p {
    text-align: justify;
    font-size: 1.4rem;
}
.project-card-actions {
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: 10px;
    width: 90%;
}

.stack-img {
    width: 25px;
    height: 25px;
}
.project-card-stacks {
    display: flex;
    flex-direction: row;
}

.project-link {
    text-decoration: none;
    color: #552ef4fc;
    font-size: 1.3rem;
    position: absolute;
    right: 25px;
}
.project-link svg {
    position: absolute;
    bottom: 2px;
    width: 25px;
    height: 20px;
}
