.languages-selector-container {
    position:relative;
    top:10px;
    right: -10px;
    padding: 0 5px;
    cursor: pointer;
    width: 120px;
}
.languages-selector-container span {
    font-size: 1.4rem;
    color: rgba(0, 0, 0, 0.6);
    text-transform: uppercase;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}
img.language-image {
    width: 25px;
    padding-right: 5px;
}

.languages-selector-content {
    display: flex;
    flex-direction: row;
    position: absolute;
    box-shadow:rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
    background-color: #fff;
    top:40px;
    width: 120px;
    padding: 0 5px;
    left: 0;
    transition: opacity 232ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 155ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.language-selected {
    display: flex;
}
.language-container {
    display: flex;
    flex-direction: row;
    padding: 5px 0;
}

@media only screen and (max-width:900px) {
    .languages-selector-container {
        width: 160px;
    }
    .languages-selector-content {
        width: 160px;
        top:50px;
    }
    .languages-selector-container {
        right: -10px;
    }
    img.language-image {
        width: 50px;
    }
}