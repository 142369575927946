.contact {
    margin-bottom: 10px;
}

.contact-container {
    display: flex;
    flex-direction: row;
}

.form-container {
    display: flex;
    flex: 0.6 1;
    width: 100%;
}

.form-container form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.input-container {
    width: 100%;
}

.label-content {
    color: #552ef4fc;
    display: inline-flex;
    padding: 0 5px;
    transform: translate(25px,50%);
    font-weight: 700;
    background-color: #fafafa;
}

.label-content-error {
    color: #f52222;
}

.input-content {
    color: #552ef4fc;
    border: 4px solid #552ef4fc;
    transition: border 0.2s ease-in-out;
    background-color: #fafafa;
}
.input-content-error {
    color: #f52222;
    border: 4px solid #f52222;
    transition: border 0.2s ease-in-out;
}
.form-input, .form-message {
    box-sizing: border-box;
    border-radius: 50px;
    outline: none;
    margin-bottom: 1rem;
    padding: .7rem;
    border-radius: 10px;
    width: 100%;
    resize: none;
}

.form-input {
    height: 50px;
}

.submit-btn {
    display: flex;
    text-decoration: none;
    border: 2px solid #552ef4fc;
    background-color: #552ef4fc;
    border-radius: 10px;
    color: #fafafa;
    overflow: hidden;
    padding: 12px 24px;
    position: relative;
    text-decoration: none;
    transition: .2s transform ease-in-out;
    will-change: transform;
    z-index: 0;
    margin-right: 20px;
    align-items: center;
    font-weight: 600;
}

.submit-btn:after {
    background-color: #fafafa;
    border-radius: 10px;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-100%, 0) rotate(10deg) scale(0);
    transform-origin: top left;
    transition: .2s transform ease-out;
    will-change: transform;
    z-index: -1;
}

.submit-btn:hover::after {
    transform: translate(0, 0);
} 

.submit-btn:hover {
    border: 2px solid transparent;
    color: #552ef4fc;
    transform: scale(1.05);
    will-change: transform;
}

.submit-btn-left:after {
    transform: translate(-100%, 0) rotate(10deg) scale(0);
}

.social-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    flex: 0.6 1;
    box-sizing: border-box;
    padding-left: 7rem;
    margin-top: 1rem;
    padding-top: 10px;
}

.social-container a {
    text-decoration: none;
}

.social-icon-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: fit-content;
    margin-bottom: 2.5rem;
}

.social-icon {
    border: 2px solid #552ef4fc;
    color: #fafafa;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    border-radius: 50%;
    justify-content: center;
    background-color: #552ef4fc;
    transition: .2s transform ease-in-out;
    will-change: transform;
    z-index: 0;
}
.social-icon:after {
    background-color: #fafafa;
    border-radius: 50%;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-100%, 0) rotate(10deg) scale(0);
    transform-origin: top left;
    transition: .2s transform ease-out;
    will-change: transform;
    z-index: -1;
}
.social-icon:hover::after {
    transform: translate(0, 0);
} 

.social-icon:hover {
    border: 2px solid transparent;
    color: #552ef4fc;
    transform: scale(1.05);
    will-change: transform;
}


.social-content {
    margin-left: 1.5rem;
    line-height: 110%;
    font-weight: 650;
    word-break: break-word;
    color: #552ef4fc;
}

.socials {
    display: flex;
    flex-direction: row;
}
.socials a {
    margin-right: 2rem;
}

.error-content {
    background: #c51244 !important;
    padding: 10px !important;
    border-radius: 0 !important;
    position: relative; 
    display: inline-block !important;
    box-shadow: 1px 1px 1px #aaaaaa;
    margin-top: 10px;
}

.error-content::before {
    content: '';  
    width: 0;  
    height: 0;  
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #c51244;
    position: absolute;  
    top: -10px; 
}

.snackbar-form {
    -webkit-box-shadow: 5px 5px 15px 5px #000000; 
    box-shadow: 5px 5px 15px 5px #000000;
}

.social-icon svg {
    width: 3rem !important;
    height: 3rem !important;
}

#socials-img {
    position: relative;
    width: 80%;
    top: -230px;
    right: -80px;
}

.submit-btn svg {
    width: 2.5rem !important;
    height: 2.5rem !important;
    padding-left: 10px !important;
    padding-top: 1.75px !important;
}

@media only screen and (max-width : 899px) {
    .contact-container {
        flex-direction: column-reverse;
    }
    .social-container p {
        display: none;
    }
    .social-container {
        flex-direction: row;
        padding-left: 0;
    }

    #socials-img {
        display:none
    }
}