@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

* {
  letter-spacing: 2px;
  line-height: 25px;
}

:root {
  font-family: 'Montserrat', sans-serif;
  font-size:10px;
  --body-font-size:1.8rem;
  --h1:calc(var(--body-font-size) * 1.96);
  --h2:calc(var(--body-font-size) * 1.56);
  --h3:calc(var(--body-font-size) * 1.16);
  --h4:calc(var(--body-font-size) * 1);
  --h5:calc(var(--body-font-size) * 0.86);
  --h4:calc(var(--body-font-size) * 0.76);
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:  #fafafa;
  letter-spacing: 2px;
  line-height: 25px;
  font-size: var(--body-font-size);
  color: #040c2c;
}

section {
  display: block;
  width: 100%;
  min-height: 100vh;
}

.section-heading {
  text-align: center;
  text-transform: uppercase;
  font-weight: 800;
  text-transform: uppercase;
  padding-bottom: 50px;
  border-bottom: 1px solid #dadce4;
  padding-top: 50px;
  margin-bottom: 50px;
}

h1 {
  font-size: var(--h1);
}

h2 {
  font-size: var(--h2);
}

h3 {
  font-size: var(--h3);
}

h4 {
  font-size: var(--h4);
}

p, span, label, input, a , textarea, i {
  font-size: var(--body-font-size);
}

ul{
	padding: 0;
  margin: 0 auto;
  list-style: none;
}
ul li {
	list-style: none;
}

.container {
  max-width: 1170px;  
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media only screen and (min-width : 900px)  and (max-width : 1199px) {

}

@media only screen and (max-width : 599px) {
  :root {
    --body-font-size:1.6rem;
    --h1:calc(var(--body-font-size) * 1.5);
    --h2:calc(var(--body-font-size) * 1.3);
    --h3:calc(var(--body-font-size) * 1.1);
    --h4:calc(var(--body-font-size) * 1);
    --h5:calc(var(--body-font-size) * 0.86);
    --h4:calc(var(--body-font-size) * 0.76);
  }


}