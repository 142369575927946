#tech-slider {
    margin: 10px;
}

.about .container p {
    margin: 0 25px;
    text-align: justify;
}

#font-slider {
}

.stack-box {
    margin: 10px 20px 10px 20px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12);
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 200px !important;
    height: 200px;
    border: 1px solid #552ef4fc;
    border-radius: 5px 5px 5px 5px;
    -webkit-border-radius: 5px 5px 5px 5px;
    -moz-border-radius: 5px 5px 5px 5px;
    padding: 2rem 1rem;
    transition: 300ms ease-in-out;
}

.stack-box:hover  {
    transform: scale(1.15);
}

.stack-box img{
    max-width: 150px;
    min-width: 150px;
}

.stack-box h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    text-align: center;
    margin-top: 1rem;
}

.marquee {
    padding: 3rem 0;
}

@media only screen and (max-width : 1599px) {    
}
@media only screen and (max-width : 1199px) {
}
@media only screen and (max-width : 899px) {
}
@media only screen and (max-width : 599px) {
}
