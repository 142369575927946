.home {
    background: url("../../../public/home/inspiration/background.png") no-repeat right top fixed;
    -webkit-background-size: 65% 100%;
    -moz-background-size: 65% 100%;
    -o-background-size: 65% 100%;
    background-size: 65% 100%;
}

.home_bubble .bubble {
    position: absolute;
    border-radius: 50%;
}

.home_bubble .bubble.b_one,
.home_bubble .bubble.b_four {
    width: 10px;
    height: 10px;
}

.home_bubble .bubble.b_two,
.home_bubble .bubble.b_five {
    width: 30px;
    height: 30px;
}

.home_bubble .bubble.b_three,
.home_bubble .bubble.b_six {
    width: 14px;
    height: 14px;
}

.home_bubble .bubble.b_one {
    background: #00c99c;
    top: 25%;
    left: 85px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-animation: spin2 2s infinite alternate;
    animation: spin2 2s infinite alternate;
    z-index: -99;
}

.home_bubble .bubble.b_two {
    background: #fca249;
    top: 45%;
    left: 155px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-animation: spin1 2s infinite alternate;
    animation: spin1 2s infinite alternate;
    z-index: -99;
}

.home_bubble .bubble.b_three {
    background: #7d95fa;
    top: 85%;
    left: 255px;
    -webkit-animation: spin1 1s infinite alternate;
    animation: spin1 1s infinite alternate;
    z-index: -99;
}

.home_bubble .bubble.b_four {
    background: #d27dfa;
    top: 100px;
    left: 205px;
    -webkit-animation: spin1 2s infinite alternate;
    animation: spin1 2s infinite alternate;
    z-index: -99;
}

.home_bubble .bubble.b_five {
    background: #ff9398;
    top: 150px;
    left: 355px;
    -webkit-animation: spin1 3s infinite alternate;
    animation: spin1 3s infinite alternate;
    z-index: -99;
}

.home_bubble .bubble.b_six {
    background: #f1d53b;
    top: 75%;
    left: 55px;
    -webkit-animation: spin2 2s infinite alternate;
    animation: spin2 2s infinite alternate;
    z-index: -99;
}
#presentationContainer {
    padding-top: 200px;
}

.home-title {
    font-size: calc(var(--h1)*1.7);
    line-height: 36px;
}
.subtitle-box {
    display: flex;
    flex-direction: column;
}
.home-subtitle {
    width: fit-content;
    padding: 12px 24px;
    border-radius: 1px;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    background-color: #eae4f6;
    color: #552ef4fc;
}
.btn-box {
    display: flex;
    flex-direction: row;
    gap: 12px;
}
.btn-home {
    text-decoration: none;
    border: 2px solid #552ef4fc;
    background-color: #552ef4fc;
    border-radius: 10px;
    color: #fafafa;
    overflow: hidden;
    padding: 12px 24px;
    text-decoration: none;
    transition: .2s transform ease-in-out;
    will-change: transform;
    z-index: 0;
    font-weight: 600;
}
.btn-home:after {
    background-color: #fafafa;
    border-radius: 10px;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(200%, 0) rotate(10deg) scale(0);
    transform-origin: top left;
    transition: .2s transform ease-out;
    will-change: transform;
    z-index: -1;
}

.btn-home:hover::after {
    transform: translate(0, 0);
} 

.btn-home:hover {
    border: 2px solid transparent;
    color: #552ef4fc;
    transform: scale(1.05);
    will-change: transform;
}

.btn-home-left:after {
    transform: translate(-100%, 0) rotate(10deg) scale(0);
}

.btn-home-contact {
    cursor:pointer;
}

#imagesContainer > div {
    position: absolute;
}

.scale-in-center {
	-webkit-animation: scale-in-center 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-center 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* Block du bureau */ 
#devContainer { 
    top: 360px;
    right: 260px;
}
#dev {
    width: 185px;
}

#deskContainer {
    top: 310px;
    right: 125px
}
#desk {
    width: 280px;
}

#ondeskContainer {
    top: 425px;
    right: 140px;
}
#ondesk {
    width: 120px;
}

#shadowContainer {
    top: 260px;
    right:50px
}
#shadow {
    width: 450px;
}

/* Block de gauche du bureau */
#sqlContainer {
    top: 170px;
    right: 460px;
}
#sql {
    width: 40px;
    -webkit-animation: slide-top-bot 5.6s ease-in-out infinite ;
    animation: slide-top-bot 5.6s ease-in-out infinite ;
}

#javaContainer {
    top: 300px;
    right: 400px;
}
#java {
    width: 40px;
    -webkit-animation: slide-top-bot 8s ease-in-out infinite ;
    animation: slide-top-bot 8s ease-in-out infinite ;
}

#springContainer {
    top: 230px;
    right: 320px;
}
#spring {
    width: 40px;
    -webkit-animation: slide-top-bot 7.5s ease-in-out infinite ;
    animation: slide-top-bot 7.5s ease-in-out infinite ;
}

/* Block de droite du bureau */
#angularContainer {
    top: 260px;
    right: 200px;
}
#angular {
    width: 40px;
    -webkit-animation: slide-top-bot 5.8s ease-in-out infinite ;
    animation: slide-top-bot 5.8s ease-in-out infinite ;
}

#reactContainer {
    top: 370px;
    right: 130px;
}
#react {
    width: 40px;
    -webkit-animation: slide-top-bot 6.3s ease-in-out infinite ;
    animation: slide-top-bot 6.3s ease-in-out infinite ;
}

#htmlContainer {
    top: 310px;
    right: 50px;
}
#html {
    width: 40px;
    -webkit-animation: slide-top-bot 6.4s ease-in-out infinite ;
    animation: slide-top-bot 6.4s ease-in-out infinite ;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: translateX(-10px) scale(0.9);
        transform: translateX(-10px) scale(0.9);
    }
    100% {
        -webkit-transform: translateX(30px) scale(1.3) translateY(10px);
        transform: translateX(30px) scale(1.3) translateY(10px);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: translateX(-10px) scale(0.9);
        transform: translateX(-10px) scale(0.9);
    }
    100% {
        -webkit-transform: translateX(30px) scale(1.3) translateY(10px);
        transform: translateX(30px) scale(1.3) translateY(10px);
    }
}

@-webkit-keyframes spin1 {
    0% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
    100% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
    }
}

@keyframes spin1 {
    0% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
    100% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
    }
}

@-webkit-keyframes spin2 {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(40px);
        transform: translateY(40px);
    }
}

@keyframes spin2 {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(40px);
        transform: translateY(40px);
    }
}
@-webkit-keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
}

@keyframes scale-in-center {
    0% {
        -webkit-transform: scale(0);
                transform: scale(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }
}

@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(0.8, 0.8);
    }
    50% {
        transform: scale(1.1, 1.1);
    }
    100% {
        transform: scale(0.8, 0.8);
    }
}

@-webkit-keyframes zoom-in-zoom-out {
    0% {
        transform: scale(0.8, 0.8);
    }
    50% {
        transform: scale(1.1, 1.1);
    }
    100% {
        transform: scale(0.8, 0.8);
    }
}

@-webkit-keyframes slide-top-bot {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    50% {
        -webkit-transform: translateY(-20px);
                transform: translateY(-20px);
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

@keyframes slide-top-bot {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    50% {
        -webkit-transform: translateY(-20px);
                transform: translateY(-20px);
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

@media only screen and (max-width : 1599px) {
    
    .home-title {
        font-size: calc(var(--h1)*1.4);
    }
    

}
@media only screen and (max-width : 1199px) {
    
    .home-title {
        font-size: calc(var(--h1)*1.3);
    }

    #sqlContainer, #htmlContainer {
        display:none;
    }
    
    #devContainer { 
        top: 155px;
        right: 140px;
    }
    #dev {
        width: 105px;
    }

    #deskContainer {
        top: 130px;
        right: 70px
    }
    #desk {
        width: 150px;
    }

    #ondeskContainer {
        top: 190px;
        right: 65px;
    }
    #ondesk {
        width: 120px;
    }

    #shadowContainer {
        top: 65px;
        right:50px
    }
    #shadow {
        width: 210px;
    }

    #javaContainer {
        top: 110px;
        right: 75px;
    }    
    #springContainer {
        top: 115px;
        right: 215px;
    }

    #angularContainer {
        top: 80px;
        right: 145px;
    }    
    #reactContainer {
        top: 190px;
        right: 21px;
    }

}

@media only screen and (max-width : 899px) {
    #home {
        background:none;
    }
    #presentationContainer{
        padding-top: 100px;
    }
    
    .home-title {
        font-size: calc(var(--h1)*1.1);
        text-align: center;
    }
    .home-subtitle {
        font-size: calc(var(--h2)*1);
        text-align: center;
        width: auto;
    }

    .btn-box {
        flex-direction: column;
    }
    .btn-home {
        text-align: center;
    }
    #imagesContainer {
        display: none;
    }
}

@media only screen and (max-width : 599px) {
    .home-title {
        font-size: calc(var(--h1)*1.5);
    }

}
