.education {
    padding-bottom: 10px;
    background:  #e4e7f6;
}
.timeline-horizontal-border {
    display:  flex;
    align-items: center;
    padding: 26px 0 30px;
}
.timeline-horizontal-border i {
    font-size:  10px;
    color: #552ef4fc;
    margin-right:  5px;
}
span.single-timeline-horizontal {
    display:  inline-block;
    background: #eae4f6;
    height:  1px;
    width: 380px;
}


.timeline {
    position: relative;
    padding: 0;
    list-style: none;
}

.education-content{
    position: relative;
    margin: 30px 0 30px;
}

.education-content:before{
    content: "";
    position: absolute;
    top: -8px;
    width: 10px;
    height: 10px;
    background-color: #d6cdef;
    margin-left: 3px;
    -webkit-transform: rotate(45deg);
       -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
         -o-transform: rotate(45deg);
            transform: rotate(45deg);
}

.education-content:after{
    content: "";
    position: absolute;
    bottom: -8px;
    width: 10px;
    height: 10px;
    background-color: #d6cdef;
    margin-left: 3px;
    -webkit-transform: rotate(45deg);
       -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
         -o-transform: rotate(45deg);
            transform: rotate(45deg);
}

.timeline:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2px;
    margin-left: 7px;
    background-color: #d6cdef;
}

.timeline>li {
    position: relative;
    margin-bottom: 50px;
    min-height: 50px;
}

.timeline>li:before,
.timeline>li:after {
    content: " ";
    display: table;
}

.timeline>li:after {
    clear: both;
}

.timeline>li .timeline-panel {
    float: left;
    position: relative;
    width: 100%;
}

.timeline-content{
    padding:5px 20px 0 20px;
}

.timeline>li .timeline-panel:before {
    right: auto;
    left: -15px;
    border-right-width: 15px;
    border-left-width: 0;
}

.timeline>li .timeline-panel:after {
    right: auto;
    left: -14px;
    border-right-width: 14px;
    border-left-width: 0;
}

.timeline>li .posted-date {
    position: relative;
    text-align: center;
    background-color: #fff;
    left: 20px;
}


.timeline>li .posted-date .month {
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
    display: block;
    color: #552ef4fc;
    top: 0;
    float: left;
    background: #e4e7f6;
}

.timeline>li.timeline-inverted>.timeline-panel {
    float: right;
    text-align: left;
}

.timeline>li.timeline-inverted>.timeline-panel:before {
    right: auto;
    left: -15px;
    border-right-width: 15px;
    border-left-width: 0;
}

.timeline>li.timeline-inverted>.timeline-panel:after {
    right: auto;
    left: -14px;
    border-right-width: 14px;
    border-left-width: 0;
}

.timeline>li:last-child {
    margin-bottom: 0;
}

.timeline-heading h3 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
}


.timeline-heading span{
    display: block;
    font-weight: 700;
    margin-bottom: 20px;
}

.month {
    background: #f9fbfd;
}

@media(min-width:900px) {
    .education-content:before {
        left: 50%;
        margin-left: -6px;
    }
    .education-content:after {
        left: 50%;
        margin-left: -6px;
    }
    .timeline:before {
        left: 50%;
        margin-left: -1.5px;
    }

    .timeline>li {
        margin-bottom: 15px;
        min-height: 100px;
    }

    .timeline>li .posted-date {
        position: absolute;
        text-align: center;
        background-color: #fff;
    }

    .timeline>li .timeline-panel {
        float: left;
        width: 40%;
        text-align: right;
        border: 1px solid #eee;
        position: relative;
    }

    .timeline-content{
        position: relative;
        padding: 12px 24px;
        background-color: #fff;
        -webkit-transition:all 400ms;
        -moz-transition:all 400ms;
        -o-transition:all 400ms;
        transition: all 400ms;
    }

    .timeline-content::after{
        border: 1px solid #eee;
        width: 100%;
        position: absolute;
        content: "";
        bottom: -3px;
        height:10px;
        left: 0;
        z-index: -1;
    }

    .timeline>li .timeline-content:hover {
        background: #552ef4fc;
        color: #fff;
    }
    
    .timeline>li .timeline-panel::before,
    .timeline>li.timeline-inverted .timeline-panel::before {
        content: "";
        top: 25px;
        position: absolute;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
    }
    .timeline>li .timeline-panel::before {
        left: 100%;
        border-left: 12px solid #ECECEC;
        -webkit-transition:all 400ms;
        -moz-transition:all 400ms;
        -o-transition:all 400ms;
        transition: all 400ms;
    }
    .timeline>li .timeline-panel:hover::before {
        border-left: 12px solid #552ef4fc;
    }

    .timeline>li.timeline-inverted .timeline-panel::before {
        right: 100%;
        border-right: 12px solid #ECECEC;
        -webkit-transition:all 400ms;
        -moz-transition:all 400ms;
        -o-transition:all 400ms;
        transition: all 400ms;
    }
    .timeline>li.timeline-inverted .timeline-panel:hover::before {
        border-right: 12px solid #552ef4fc;
        border-left: 0;
    }

    .timeline>li .timeline-panel::after,
    .timeline>li.timeline-inverted .timeline-panel::after {
        content: "";
        top: 25px;
        position: absolute;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
    }
    .timeline>li .timeline-panel::after {
        left: 100%;
        margin-left: -2px;
        border-left: 12px solid #FFFFFF;
        -webkit-transition:all 400ms;
        -moz-transition:all 400ms;
        -o-transition:all 400ms;
        transition: all 400ms;
    }
    .timeline>li .timeline-panel:hover::after {
        border-left: 12px solid #552ef4fc;
    }

    .timeline>li.timeline-inverted .timeline-panel::after {
        right: 100%;
        margin-right:-2px;
        border-right: 12px solid #FFFFFF;
        -webkit-transition:all 400ms;
        -moz-transition:all 400ms;
        -o-transition:all 400ms;
        transition: all 400ms;
    }
    .timeline>li.timeline-inverted .timeline-panel:hover::after {
        border-right: 12px solid #552ef4fc;
        border-left: 0;
    }

    .timeline>li .posted-date {
        left: 50%;
        width: 130px;
        margin-left: -65px;
        margin-top: 28px;
    }
    .timeline>li .posted-date .month {
        float: none;
    }


}