.portfolio {
    background:  #e4e7f6;
}

#portfolio-container {
}

.slick-prev::before {
    color: #552ef4fc!important;
}

.slick-next::before {
    color: #552ef4fc!important;
}
