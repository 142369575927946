.experience {
    padding: 50px 0 100px;
    border-top: 1px solid #e5ebf2;
}

@media only screen and (max-width : 1599px) {    
}
@media only screen and (max-width : 1199px) {
}
@media only screen and (max-width : 899px) {
}
@media only screen and (max-width : 599px) {
}
