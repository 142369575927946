.footer {
    padding: 30px 30px 20px 30px;
    background:  #e4e7f6;
}

.love-icon {
    color: #f30c0c;
}

.love-icon svg {
    -webkit-animation: heartbeat 3s ease-in-out infinite;
    animation: heartbeat 3s ease-in-out infinite;
}

.footer-content {
    text-align: right;
    font-weight: 600;
}

@keyframes heartbeat {
    0% {
        transform: scale(0.8, 0.8);
    }
    50% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(0.8, 0.8);
    }
}

@-webkit-keyframes heartbeat {
    0% {
        transform: scale(0.8, 0.8);
    }
    50% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(0.8, 0.8);
    }
}

@media only screen and (max-width : 899px) {
    .footer-content {
        text-align: center;
    }
}